
























































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { toLocalCurrency } from '@/utils/currencyFormatter'
import { namespace } from 'vuex-class'
const PlansStore = namespace('PlansStore')
const PlaceTypesStore = namespace('PlaceTypesStore')
const UserStore = namespace('UserStore')
import { PlaceTypesModel } from '@/core/models/PlaceTypesModel'
import { AddPremiumInterface } from './AddPremium.vue'
import { PlanModel, OfferModel } from '@/core/models/PlanModel'
import { UserModel } from '@/core/models/UserModel'

import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseCheckbox from '@/components/forms/BaseCheckbox.vue'
import { TippyComponent } from 'vue-tippy/dist/vue-tippy.esm'

class CheckOfferModel extends OfferModel {
  name = ''
}

interface ItemInterface {
  id: number
  name: string
  disabled: boolean
  tooltip: string
}

@Component({
  name: 'AddPremiumCheck',
  components: {
    BaseIcon,
    BaseButton,
    BaseCheckbox,
    TippyComponent,
  },
})
export default class AddPremiumCheck extends Vue {
  @Prop({ required: true })
  value!: AddPremiumInterface

  @Watch('paymentMethod')
  onPaymentMethodChanged(id: number): void {
    this.selectPaymentMethod(id)
  }

  @UserStore.Getter
  public currentUser!: UserModel

  @PlansStore.Getter
  public planList!: PlanModel[]

  @PlaceTypesStore.Getter
  public placeTypesList!: PlaceTypesModel[]

  get account(): number {
    return Number(this.currentUser.account) || 0
  }

  get totalPrice(): number {
    return this.offers.reduce((sum, offer) => sum + offer.price, 0)
  }

  get accountOff(): number {
    return this.account > 0 ? Math.min(this.totalPrice, this.account) : 0
  }

  get pay(): number {
    return this.totalPrice > this.accountOff
      ? this.totalPrice - this.accountOff
      : 0
  }

  get offers(): CheckOfferModel[] {
    const baseOffers = ([] as OfferModel[])
      .concat(...this.planList.map((plan) => plan.offers))
      .filter((offer) => this.value.offers.includes(offer.id))
    return baseOffers.map(
      (offer) =>
        ({
          ...offer,
          name: this.placeTypesList.find(
            (placeType) => placeType.id === offer.place_type
          )?.name,
        } as CheckOfferModel)
    )
  }

  get items(): ItemInterface[] {
    return [
      {
        id: 1,
        name: 'Оплата картой или списанием с личного счета',
        disabled: false,
        tooltip: '',
      },
      {
        id: 2,
        name: 'Выставить счет для юрлиц',
        disabled: !this.currentUser.invoice,
        tooltip: !this.currentUser.invoice ? 'invoice' : '',
      },
    ]
  }

  toLocalCurrency = toLocalCurrency
  paymentMethod = 1

  selectPaymentMethod(value: number): void {
    const newValue: AddPremiumInterface = {
      ...this.value,
      paymentMethod: value,
    }
    this.$emit('input', newValue)
  }

  onChangePaymentMethod(event: any): void {
    this.paymentMethod = +event.target.value
  }
}
